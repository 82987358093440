import styled from "styled-components";

const ProductStyle = styled.div`
  padding: 20px 50px 0 50px;
  .none {
    display: none;
  }
  .backBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 7px 20px;
    background-color: #192d75;
    color: #ffffff;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .listTitle {
      font-size: 18px;
      margin: 0;
      padding: 0;
      font-weight: 600;
    }
  }
  .noData {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .noDataTitle {
      font-size: 20px;
    }
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    transition: 0.5s;
    th,
    td {
      border: 1px solid #192d75;
      border-collapse: collapse;
      text-align: center;
    }
    tr {
      transition: 0.3s;
    }
    tr:hover {
      background-color: #192d7523;
    }
    tbody {
      background-color: #192d750e;
      .ID {
        font-size: 19px;
        font-weight: 600;
      }
      .title {
        margin: 0;
        text-align: start;
        text-align: center;
      }
      .date1 {
        margin: 0;
        color: #1aa308;
        font-weight: 600;
      }
      .date2 {
        margin: 0;
        color: red;
        font-weight: 600;
      }
      .prosentContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      .protsent {
        border: 2px solid #3d0dad;
        width: 60%;
        height: 20px;
        border-radius: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 2px;
      }
      .miniProtsent {
        height: 100%;
        background-color: #3d0dad;
        border-radius: 20px;
      }
      .confirm {
        background-color: #1aa308;
        border-radius: 5px;
        padding: 10px 17px;
        margin: 0 5px;
        color: #ffffff;
        font-weight: 600;
        border: none;
      }
      .confirm2 {
        background-color: #83c87a;
        border-radius: 5px;
        padding: 10px 17px;
        margin: 0 5px;
        color: #ffffff;
        font-weight: 600;
        border: none;
      }
    }
  }
`;
export default ProductStyle;
