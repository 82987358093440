import React, { useEffect, useMemo, useState } from "react";
import ProductStyle from "./ProductStyle";
import { get } from "lodash";
import api from "../../api";
import { ErrorModal, SuccessModal } from "../../components/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import time from "../../helpers/time";

const Product = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const mainItemID = get(state, "ID", 0);
  const WHS = get(state, "whs", "");
  const DocEntry = get(state, "DocEntry", 0);

  const [dataStages, setDataStages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  useEffect(() => {
    getAllData();
  }, []);

  const send = (ID) => {
    const config = {
      headers: {
        withCredentials: true,
      },
    };
    api
      .patch(
        `/ProductionOrders(${mainItemID})`,
        {
          ProductionOrdersStages: [
            {
              StageID: ID,
              U_Status: "Бажарилди",
            },
          ],
        },
        config
      )
      .then(() => {
        setIsOpen(true);
        getAllData();
      })
      .catch((err) => {
        console.log("err send:", err);
        setIsOpenError(true);
      });
  };

  const sendWHS = (ID, LineNum) => {
    const config = {
      withCredentials: true,
    };
    api
      .patch(
        `/Orders(${DocEntry})`,
        {
          DocumentLines: [
            {
              LineNum: LineNum,
              U_Status: "Manufactured",
            },
          ],
        },
        config
      )
      .then(() => {
        alert("success");
        getAllData();
      })
      .catch((err) => {
        console.log("err send O:", err);
      });
  };

  const getAllData = () => {
    const config = {
      headers: {
        withCredentials: true,
      },
    };
    api
      .get(
        WHS ? `/Orders(${DocEntry})` : `/ProductionOrders(${mainItemID})`,
        config
      )
      .then((res) => {
        let resData = WHS
          ? get(JSON.parse(res.data), "DocumentLines", [])
          : get(JSON.parse(res.data), "ProductionOrdersStages", []);
        setDataStages(resData);
      })
      .catch((err) => {
        console.log("err getAllData:", err);
        setIsOpenError(true);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const closeModalError = () => {
    setIsOpenError(false);
  };

  const productRender = useMemo(() => {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Название продукта</th>
            <th>Data</th>
            <th>Сделанный</th>
          </tr>
        </thead>
        <tbody>
          {dataStages.map((item, index) => {
            return (
              <tr key={index} className={"s"}>
                <td className='title'>{get(item, "DocEntry", "")}</td>
                <td className='ID'>{get(item, "Name", "")}</td>
                <td>
                  <p>{time.to(get(item, "StartDate", ""), "DD.MM.YYYY")}</p>
                </td>
                <td>
                  {WHS ? (
                    <button
                      className={
                        get(item, "U_Status", "") === "Manufactured"
                          ? "confirm2"
                          : "confirm"
                      }
                      onClick={() => sendWHS(item.DocEntry, item.LineNum)}
                      disabled={get(item, "U_Status", "") === "Manufactured"}
                    >
                      {"Бажарилди"}
                    </button>
                  ) : (
                    <button
                      className={
                        get(item, "U_Status", "") === "Бажарилди"
                          ? "confirm2"
                          : "confirm"
                      }
                      onClick={() => send(item.StageID)}
                      disabled={get(item, "U_Status", "") === "Бажарилди"}
                    >
                      {"Бажарилди"}
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }, [dataStages]);

  return (
    <ProductStyle>
      <button onClick={() => navigate("/main")} className='backBtn'>
        <AiOutlineArrowLeft />
      </button>
      {dataStages.length <= 0 ? (
        <div className='noData'>
          <p className='noDataTitle'>{"Информация недоступна"}</p>
        </div>
      ) : (
        productRender
      )}
      <SuccessModal isOpen={isOpen} onConfirm={closeModal} />
      <ErrorModal isOpen={isOpenError} onConfirm={closeModalError} />
    </ProductStyle>
  );
};

export default Product;
