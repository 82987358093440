import styled from "styled-components";

const ProfilStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100vh;
  flex-wrap: wrap;
  .card {
    padding: 30px;
    width: 100%;
    min-height: 80vh;
    .backBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 7px 20px;
      background-color: #192d75;
      color: #ffffff;
      border-radius: 5px;
    }
  }
  .centerCard {
    text-align: center;
    .name {
      text-align: center;
    }
    .JobTitle {
      text-align: center;
      color: red;
    }
    .img {
      width: 100px;
      height: 100px;
      border-radius: 25px;
      margin-top: 20px;
    }
  }

  .logoutCard {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;
export default ProfilStyle;
