import styled from "styled-components";

const MainStyle = styled.div`
  padding: 20px 50px 50px 50px;
  display: flex;
  flex-wrap: wrap;

  .listTitleBtn {
    border: none;
    background-color: #ffffff00;
    font-size: 15px;
    font-weight: bold;
  }
  .none {
    display: none;
  }
  @keyframes topImage {
    0% {
      scale: 0.7;
      transform: translateY(10px);
    }

    50% {
      scale: 1;
      transform: translateY(0);
    }
  }
  .img {
    width: 40px;
    animation: topImage 5s;
    position: absolute;
    top: 20px;
    left: calc(50% - 20px);
  }
  .dateError {
    color: #dd1111;
    font-weight: 600;
    margin: 0 10px;
  }
  .arrow {
    border: none;
    background-color: #4f6edd;
    padding: 5px 20px;
    margin: 10px;
    text-align: right;
  }
  .btnTop {
    border: none;
    background-color: #192d75;
    padding: 7px 20px;
    margin: 10px;
    text-align: right;
    color: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addCard {
    display: flex;
    justify-content: flex-end;
  }
  .header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .listTitle {
      font-size: 18px;
      margin: 0;
      padding: 0;
      font-weight: 600;
    }
  }
  .noData {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .noDataTitle {
      font-size: 20px;
    }
  }
  .card {
    width: 100%;
    .table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 5px;
      transition: 0.5s;
      th,
      td {
        border: 1px solid #192d75;
        border-collapse: collapse;
        text-align: center;
        padding: 0 5px;
      }
      tr {
        transition: 0.3s;
      }
      tr:hover {
        background-color: #192d7523;
      }
      tbody {
        background-color: #192d750e;
        .ID {
          font-size: 19px;
          font-weight: 600;
        }
        .title {
          width: 400px;
          margin: 0;
          text-align: start;
        }
        .date1 {
          color: #1aa308;
          font-weight: 600;
          margin: 0 10px;
        }
        .date2 {
          color: #119db9;
          font-weight: 600;
          margin: 0 10px;
        }
        .prosentContainer {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin: 0 10px;
        }
        .protsent {
          border: 2px solid #3d0dad;
          width: 70px;
          height: 20px;
          border-radius: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 2px;
          margin-left: 10px;
        }
        .miniProtsent {
          height: 100%;
          background-color: #3d0dad;
          border-radius: 20px;
        }
        .confirm {
          background-color: #1aa308;
          border-radius: 5px;
          padding: 10px 17px;
          margin: 0 5px;
          color: #ffffff;
          font-weight: 600;
          border: none;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .prosentContainer {
      display: block;
    }
  }
`;
export default MainStyle;
