import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { main } from "../../store/slices";
import { get } from "lodash";
import ProfilStyle from "./ProfilStyle";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { AiOutlineArrowLeft } from "react-icons/ai";
import UserImage from "../../assets/images/user.png";
import LogoutModal from "../../components/Modal/LogoutModal";

const Profil = () => {
  const { info } = useSelector((state) => state.main);
  const { setMe, setInfo, setLogins } = main.actions;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const login = get(info, "login", "");
  const password = get(info, "password", "");

  useEffect(() => {
    getProfil();
  }, []);

  const getProfil = () => {
    api
      .get(
        `/EmployeesInfo?$filter=OfficePhone eq '${login}' and OfficeExtension eq '${password}'`
      )
      .then((res) => {
        setData(get(JSON.parse(res.data), "value[0]", []));
      })
      .catch((err) => {
        console.log("err getProfil:", JSON.stringify(err, null, 2));
      });
  };

  const logout = () => {
    navigate("/login");
    dispatch(setMe({}));
    // dispatch(setInfo({}));
    dispatch(setLogins(false));
  };

  return (
    <>
      <ProfilStyle>
        <div className='card'>
          <button onClick={() => navigate("/redirect")} className='backBtn'>
            <AiOutlineArrowLeft />
          </button>

          <div className='centerCard'>
            <img src={UserImage} alt='user Image' className='img' />
            <h2 className='name'>
              {get(data, "FirstName", "")} {get(data, "LastName", "")}{" "}
            </h2>
            <h3 className='JobTitle'>{get(data, "JobTitle", "")}</h3>
          </div>
        </div>
        <div className='logoutCard'>
          <Button onClick={() => setIsOpen(true)}>Выход</Button>
        </div>
      </ProfilStyle>
      <LogoutModal
        isOpen={isOpen}
        onConfirm={logout}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default Profil;
