import React, { useEffect } from "react";
import LaunchStyle from "./LaunchStyle";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo2 from "../../assets/images/logo2.png";

const Launch = () => {
  const { login } = useSelector((state) => state.main);

  const navigate = useNavigate();

  useEffect(() => {
    if (login) {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    }
  }, []);

  return (
    <LaunchStyle>
      <div className='container'>
        <img src={Logo2} alt='logo' className='img' />
      </div>
    </LaunchStyle>
  );
};

export default Launch;
