import React, { useState, useEffect, useMemo } from "react";
import { ErrorModal, SuccessModal } from "../../components/Modal";
import MainStyle from "./MainStyle";
import { main } from "../../store/slices";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import YesNoModal from "./YesNoModal";
import { get } from "lodash";
import api from "../../api";
import Logo from "../../assets/images/logo.png";
import { AiOutlinePlusCircle } from "react-icons/ai";
import time from "../../helpers/time";

const Main = () => {
  const { setMe, setInfo, setLogins } = main.actions;
  const dataMe = useSelector((state) => state.main);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isOmborchi = get(dataMe, "userName[0].JobTitle", "") === "Омборчи";

  const [data, setData] = useState({
    data: [],
    currentPage: get(dataMe, "u_prefix", ``),
    oldPage: 0,
  });
  const [dataO, setDataO] = useState({
    data: [],
    currentPage: get(dataMe, "u_prefixO", ``),
    oldPage: 0,
  });
  const [myItem, setMyItem] = useState({});
  const [dataBops, setDataBops] = useState({});
  const [yesNoOmborchi, setYesNoOmborchi] = useState(false);
  const [yesNoBrigadir, setYesNoBrigadir] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

  useEffect(() => {
    mainProducts(data, data.currentPage);
    simpleProducts(dataO, dataO.currentPage);
  }, []);

  const mainProducts = (data, currentPage) => {
    const config = {
      withCredentials: true,
    };

    api
      .get(`/${currentPage}`, config)
      .then((res) => {
        let nextLink = get(JSON.parse(res.data), "odata.nextLink", "");
        let nextLinkNumber = parseInt(
          get(JSON.parse(res.data), "odata.nextLink", "").slice(-2)
        );

        let resData = get(JSON.parse(res.data), "value", []);

        if (nextLink && data.oldPage < nextLinkNumber) {
          setData({
            data: [...data.data, ...resData],
            currentPage: nextLink,
            oldPage: nextLinkNumber,
          });
        } else {
          setData({
            data: [...data.data, ...resData],
            currentPage: ``,
            oldPage: 0,
          });
        }
      })
      .catch((err) => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate("/login");
          dispatch(setMe({}));
          dispatch(setInfo({}));
          dispatch(setLogins(false));
        } else {
          console.log("err mainProducts:", err);
          setIsOpenErrorModal(true);
        }
      });
  };

  const simpleProducts = (data, currentPage) => {
    const config = {
      withCredentials: true,
    };

    api
      .get(`${currentPage}`, config)
      .then((res) => {
        let nextLink = get(JSON.parse(res.data), "odata.nextLink", "");
        let nextLinkNumber = parseInt(
          get(JSON.parse(res.data), "odata.nextLink", "").slice(-2)
        );

        let resData = get(JSON.parse(res.data), "value", []);
        resData = resData.map((v, i) => {
          return { ...v, Whs: true };
        });

        if (nextLink && data.oldPage < nextLinkNumber) {
          setDataO({
            data: [...data.data, ...resData],
            currentPage: nextLink,
            oldPage: nextLinkNumber,
          });
        } else {
          setDataO({
            data: [...data.data, ...resData],
            currentPage: ``,
            oldPage: 0,
          });
        }
      })
      .catch((err) => {
        console.log("err simpleProducts:", err);
      });
  };

  const addNewProducts = () => {
    if (data.currentPage.length > 1) {
      mainProducts(data, data.currentPage);
    }
    if (dataO.currentPage.length > 1) {
      simpleProducts(dataO, dataO.currentPage);
    }
  };

  // BRIGADIR
  const checking = (item) => {
    const data = item.ProductionOrderLines.filter((itemL) => {
      return itemL.ProductionOrderIssueType === "im_Manual";
    });

    const config = {
      withCredentials: true,
    };

    api
      .get(`/SQLQueries('sqlIGE1')/List?doc=${item.AbsoluteEntry}`, config)
      .then((res) => {
        let resData = get(JSON.parse(res.data), "value", []);

        if (
          resData.length >= data.length &&
          item.ProductionOrderStatus === "boposReleased"
        ) {
          navigate("/product", {
            state: { ID: item.AbsoluteEntry, whs: false },
          });
        } else {
          setYesNoBrigadir(true);
          setDataBops(item);
        }
      })
      .catch((err) => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate("/login");
          dispatch(setMe({}));
          dispatch(setInfo({}));
          dispatch(setLogins(false));
        } else {
          // errorModalRef.current?.open(
          //   JSON.parse(err.response.data).error.message.value
          // );
          setIsOpenErrorModal(true);
        }
      });
  };

  const makeBoposPlanned = (item) => {
    const data = item.ProductionOrderLines.filter((itemL) => {
      return itemL.ProductionOrderIssueType === "im_Manual";
    });

    const config = {
      withCredentials: true,
    };
    api
      .patch(
        `/ProductionOrders(${item.AbsoluteEntry})`,
        {
          ProductionOrderStatus: "boposReleased",
        },
        config
      )
      .then(() => {
        console.log("data.length", data.length);
        if (data.length !== 0) {
          allItemSending(item);
        } else {
          navigate("/product", {
            state: { ID: item.AbsoluteEntry, whs: false },
          });
        }
      })
      .catch((err) => {
        console.log("err makeBoposPlanned:", JSON.stringify(err, null, 2));
        setIsOpenErrorModal(true);
      });
  };

  const allItemSending = (item) => {
    const dataS = get(item, "ProductionOrderLines", []);

    const data = dataS.filter((item) => {
      return item.ProductionOrderIssueType === "im_Manual";
    });

    let allDatasOfItem = data.map((itemL) => {
      return {
        BaseType: 202,
        BaseEntry: item.AbsoluteEntry,
        BaseLine: itemL.LineNumber,
        Quantity: itemL.PlannedQuantity,
      };
    });
    const config = {
      withCredentials: true,
    };
    console.log("all item sending", allDatasOfItem);

    api
      .post(
        "/InventoryGenExits",
        {
          DocumentLines: allDatasOfItem,
        },
        config
      )
      .then(() => {
        setYesNoBrigadir(false);
        navigate("/product", { state: { ID: item.AbsoluteEntry, whs: false } });
      })
      .catch((err) => {
        // errorModalRef.current?.open(
        //   JSON.parse(err.response.data).error.message.value
        // );
        setYesNoBrigadir(false);
      });
  };

  const allItemSendingO = (ID, item) => {
    navigate("/product", {
      item: ID,
      whs: true,
      DocEntry: item.DocEntry,
    });
  };

  // BRIGADIR

  // ***************************************************************************************************************************

  // OMBORCHI
  const deletForSecurity = (items) => {
    setYesNoOmborchi(true);
    setMyItem(items);
  };

  const deletForSecurity2 = () => {
    setYesNoOmborchi(false);

    const config = {
      withCredentials: true,
    };

    api
      .get(`/SQLQueries('sqlIGN1')/List?doc=${myItem.AbsoluteEntry}`, config)
      .then((res) => {
        let resData = get(JSON.parse(res.data), "value", []);
        if (resData.length === 0) {
          postToInventoryGenEntries(myItem);
        } else {
          afterDelForSecurity(myItem.AbsoluteEntry);
        }
      })
      .catch((err) => {
        console.log("deletForSecurity ERROR", err);
        // errorModalRef.current?.open(
        //   JSON.parse(err.response.data).error.message.value
        // );
        setIsOpenErrorModal(true);
      });
  };

  const postToInventoryGenEntries = (items) => {
    const config = {
      withCredentials: true,
    };

    console.log("onventory", {
      BaseEntry: items.AbsoluteEntry,
      BaseType: "202",
      Quantity: items.PlannedQuantity,
    });
    api
      .post(
        `/InventoryGenEntries`,
        {
          DocumentLines: [
            {
              BaseEntry: items.AbsoluteEntry,
              BaseType: "202",
              Quantity: items.PlannedQuantity,
            },
          ],
        },
        config
      )
      .then(() => {
        afterDelForSecurity(items.AbsoluteEntry);
      })
      .catch((err) => {
        setIsOpenErrorModal(true);

        // errorModalRef.current?.open(
        //   JSON.parse(err.response.data).error.message.value
        // );
      });
  };

  const afterDelForSecurity = (ID) => {
    const config = {
      withCredentials: true,
    };

    api
      .patch(
        `/ProductionOrders(${ID})`,
        {
          ProductionOrderStatus: "boposClosed",
        },
        config
      )
      .then(() => {
        mainProducts(data, data.currentPage);
        setIsOpenSuccessModal(true);
      })
      .catch((err) => {
        console.log("ERROR afterDelForSecurity", err);
        setIsOpenErrorModal(true);
      });
  };

  const afterDelForSecurityO = (ID, LineNum) => {
    const config = {
      headers: {
        Cookie: `B1SESSION=${dataMe.token}; ROUTEID=.node1`,
      },
    };
    api
      .patch(
        `/Orders(${ID})`,
        {
          DocumentLines: [
            {
              LineNum: LineNum,
              U_Status: "Checked",
            },
          ],
        },
        config
      )
      .then(() => {
        mainProducts(
          {
            data: [],
            currentPage: get(dataMe, "u_prefixO", ``),
            oldPage: 0,
          },
          get(dataMe, "u_prefixO", ``)
        );
        alert("Success");
      })
      .catch((err) => {
        console.log("ERROR afterDelForSecurity", err);
      });
  };
  // OMBORCHI

  const closeErrorModal = () => {
    setIsOpenErrorModal(false);
  };

  const closeSuccessModal = () => {
    setIsOpenSuccessModal(false);
  };

  const omborchiRender = useMemo(() => {
    return (
      <>
        <table className='table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Название продукта</th>
              <th>Номер продукта</th>
              <th>Начала/Окончания</th>
              <th>Сделанный</th>
              <th>Принять</th>
            </tr>
          </thead>
          <tbody>
            {[...data.data, ...dataO.data].map((item, index) => {
              const WHS = get(item, "Whs", false);
              const date = new Date();
              const dd = String(date.getDate()).padStart(2, "0");
              const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
              const yyyy = date.getFullYear();
              let isDate = true;

              if (time.to(get(item, "StartDate", ""), "YYYY") <= yyyy) {
                if (time.to(get(item, "StartDate", ""), "MM") <= mm) {
                  if (
                    Math.abs(dd - time.to(get(item, "StartDate", ""), "DD")) >=
                    3
                  ) {
                    isDate = true;
                  }
                }
              }

              let stages = get(item, "ProductionOrdersStages", []);
              let status = get(item, "ProductionOrderStatus", "");
              const complatedLength = get(
                item,
                "ProductionOrdersStages",
                []
              ).filter((item) => item.U_Status === "Бажарилди").length;

              return (
                <tr
                  key={index}
                  className={
                    (stages.length ===
                      stages.filter((item) => item.U_Status === "Бажарилди")
                        .length &&
                      status === "boposReleased") ||
                    WHS
                      ? "s"
                      : "none"
                  }
                >
                  <td className='ID'>
                    {WHS
                      ? get(item, "DocNum", "")
                      : get(item, "DocumentNumber", "")}
                  </td>
                  <td className='title'>
                    {WHS
                      ? get(item, "Dscription", "")
                      : get(item, "ProductDescription", "")}
                  </td>
                  <td>
                    {WHS
                      ? get(item, "ItemCode", "")
                      : get(item, "JournalRemarks", "")}
                  </td>
                  <td>
                    <div>
                      <p className={isDate ? "dateError" : "date1"}>
                        {WHS
                          ? get(item, "DocDate", "")
                          : time.to(get(item, "StartDate", ""), "DD.MM.YYYY")}
                      </p>
                      <p className='date2'>
                        {WHS
                          ? get(item, "DocDueDate", "")
                          : time.to(get(item, "ReleaseDate", ""), "DD.MM.YYYY")}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className='prosentContainer'>
                      <p>{WHS ? `${stages.length}/${complatedLength}` : ""}</p>
                      <div className='protsent'>
                        <div
                          className='miniProtsent'
                          style={{
                            width: `${
                              complatedLength !== 0
                                ? (complatedLength * 100) / stages.length
                                : 0
                            }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <button
                      className='confirm'
                      onClick={
                        WHS
                          ? () =>
                              afterDelForSecurityO(item.DocEntry, item.LineNum)
                          : () => deletForSecurity(item)
                      }
                    >
                      Принять
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {data.currentPage ? (
          <div className='addCard'>
            <button className='btnTop' onClick={addNewProducts}>
              <AiOutlinePlusCircle />
            </button>
          </div>
        ) : null}
      </>
    );
  }, [data.currentPage]);

  const brigadirRender = useMemo(() => {
    return (
      <>
        <table className='table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Название продукта</th>
              <th>Клиент</th>
              <th>Номер продукта</th>
              <th>Начала/Окончания</th>
              <th>Сделанный</th>
              <th>Принять</th>
            </tr>
          </thead>
          <tbody>
            {[...data.data, ...dataO.data].map((item, index) => {
              const WHS = get(item, "Whs", false);

              const date = new Date();
              const dd = String(date.getDate()).padStart(2, "0");
              const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
              const yyyy = date.getFullYear();
              let isDate = false;

              if (time.to(get(item, "StartDate", ""), "YYYY") <= yyyy) {
                if (time.to(get(item, "StartDate", ""), "MM") <= mm) {
                  if (
                    Math.abs(dd - time.to(get(item, "StartDate", ""), "DD")) > 2
                  ) {
                    isDate = true;
                  }
                }
              }

              const complatedLength = WHS
                ? 0
                : get(item, "ProductionOrdersStages", []).filter(
                    (item) => item.U_Status === "Бажарилди"
                  ).length;

              const stagesLength = WHS
                ? 0
                : get(item, "ProductionOrdersStages", []).length;
              return (
                <tr key={index} className={"s"}>
                  <td className='ID'>{get(item, "DocumentNumber", "")}</td>
                  <td className='title'>
                    {get(item, "ProductDescription", "")}
                  </td>
                  <td className='title'>
                    {get(item, "BusinessPartner.CardName", "")}
                  </td>

                  <td>{get(item, "JournalRemarks", "")}</td>
                  <td>
                    <div>
                      <p className={!isDate ? "date1" : "dateError"}>
                        {time.to(get(item, "StartDate", ""), "DD.MM.YYYY")}
                      </p>
                      <p className={!isDate ? "date2" : "dateError"}>
                        {time.to(get(item, "ReleaseDate", ""), "DD.MM.YYYY")}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className='prosentContainer'>
                      <p>
                        {stagesLength}/{complatedLength}
                      </p>
                      <div className='protsent'>
                        <div
                          className='miniProtsent'
                          style={{
                            width: `${
                              complatedLength !== 0
                                ? (complatedLength * 100) / stagesLength
                                : 0
                            }%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <button
                      className='confirm'
                      onClick={
                        WHS
                          ? () =>
                              allItemSendingO(
                                get(item, "DocumentNumber", 0),
                                item
                              )
                          : () => checking(item, index)
                      }
                    >
                      Принять
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {data.currentPage ? (
          <div className='addCard'>
            <button className='btnTop' onClick={addNewProducts}>
              <AiOutlinePlusCircle />
            </button>
          </div>
        ) : null}
      </>
    );
  }, [data.currentPage]);

  return (
    <>
      <MainStyle>
        <div className='header'>
          <button
            className='listTitleBtn'
            onClick={() => navigate("/redirect")}
          >
            {"Меню"}
          </button>
          <img src={Logo} alt='logo' className='img' />
          <button onClick={() => navigate("/profil")} className='btnTop'>
            {"Профиль"}
          </button>
        </div>
        <div className='card'>
          {data.data.length <= 0 ? (
            <div className='noData'>
              <p className='noDataTitle'>Информация недоступна</p>
            </div>
          ) : isOmborchi ? (
            omborchiRender
          ) : (
            brigadirRender
          )}
        </div>
      </MainStyle>

      <YesNoModal
        isOpen={yesNoOmborchi}
        onConfirm={deletForSecurity2}
        onClose={() => setYesNoOmborchi(false)}
      />
      <YesNoModal
        isOpen={yesNoBrigadir}
        onConfirm={() => makeBoposPlanned(dataBops)}
        onClose={() => setYesNoBrigadir(false)}
      />
      <ErrorModal isOpen={isOpenErrorModal} onConfirm={closeErrorModal} />
      <SuccessModal isOpen={isOpenSuccessModal} onConfirm={closeSuccessModal} />
    </>
  );
};

export default Main;
