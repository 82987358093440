import styled from "styled-components";
import colors from "../../assets/style/colors";

const LoginStyle = styled.div`
  .text {
    color: green;
  }
  .container {
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 50px;
    .img {
      width: 150px;
      height: 150px;
      margin-top: 10px;
    }
    .card {
      width: 100%;
      margin-bottom: 50px;
      text-align: center;
      .label {
        font-size: 20px;
        color: #192d75;
      }
      .input {
        border: 2px solid #192d75;
        font-size: 20px;
        padding: 10px;
        border-radius: 5px;
        color: #192d75;
        margin-top: 10px;
      }
    }
    .btn {
      padding: 10px 30px;
      border-radius: 5px;
      background-color: #192d75;
      color: #ffffff;
      font-size: 18px;
      border: none;
    }
  }
`;
export default LoginStyle;
