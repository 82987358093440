import React, { memo, useEffect } from "react";
import SuccessModalStyle from "./SuccessModalStyle";

const SuccessModal = ({ isOpen, onConfirm }) => {
  useEffect(() => {
    setTimeout(() => {
      onConfirm();
    }, 4000);
  }, []);

  return (
    <SuccessModalStyle>
      <div className={!isOpen ? "none" : "modal"}>
        <div className='card'>
          <h2>{"Выполнено успешно!"}</h2>
          <div className='centerCard'>
            <button className='btnY' onClick={() => onConfirm()}>
              {"Понятно"}
            </button>
          </div>
        </div>
      </div>
    </SuccessModalStyle>
  );
};

export default memo(SuccessModal);
