import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Launch,
  Login,
  Main,
  Profil,
  Product,
  Redirect,
  SalesOrder,
  InsideOrder,
} from "../screens";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Launch />} />
        <Route path='/login' element={<Login />} />
        <Route path='/main' element={<Main />} />
        <Route path='/profil' element={<Profil />} />
        <Route path='/product' element={<Product />} />
        <Route path='/redirect' element={<Redirect />} />
        <Route path='/salesOrder' element={<SalesOrder />} />
        <Route path='/insideOrder' element={<InsideOrder />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
