import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import api from "../../api";
import Logo from "../../assets/images/logo.png";
import { AiOutlinePlusCircle } from "react-icons/ai";
import SalesOrderStyle from "./styles";
import moment from "moment";

const SalesOrder = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { token } = useSelector((state) => state.main);
  const [nextLink, setNextLink] = useState(0);

  function filterDate(timestamp) {
    let format = "DD.MM.YYYY";
    if (moment().diff(moment(timestamp), "year") > 0) {
      format = "DD.MM.YYYY";
    } else if (moment().diff(moment(timestamp), "day") > 0) {
      format = "DD.MM.YYYY";
    }
    return moment(timestamp).format(format);
  }

  const getProductionOrders = (skip = 0, oldData = []) => {
    //
    if (!(skip == 0 && data.length > 0)) {
      api
        .get(
          `/Orders?$filter=DocumentStatus eq 'bost_Open'&$orderby=DocNum,DocDueDate desc&$skip=${skip}`,
          {
            // headers: {
            //   Cookie: `B1SESSION=${token}; ROUTEID=.node1`,
            // },
            withCredentials: true,
          }
        )
        .then((response) => {
          const data = get(JSON.parse(response.data), "value", []);
          console.log("data", data);
          setData([...oldData, ...data]);
          setNextLink(
            get(JSON.parse(response.data), "odata.nextLink", "skip=0").split(
              "skip="
            )[1]
          );
        })
        .catch((error) => {
          console.log("salesOrder errors", error);
        });
    }
  };
  useEffect(() => {
    getProductionOrders(nextLink, data);
  }, []);

  const addNewProducts = () => getProductionOrders(nextLink, data);

  const salesProducts = useMemo(() => {
    return (
      <>
        <table className='table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Название продукта</th>
              <th>Summa</th>
              <th>Date</th>
              <th>Принять</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index} className='mainTr'>
                  <td className='ID'>{get(item, "DocNum", "s")}</td>
                  <td className='title'>{get(item, "CardName", "s")}</td>
                  <td>
                    {" "}
                    {get(item, "DocTotal", 0)}
                    {` `}
                    {get(item, "DocCurrency", "")}
                  </td>
                  <td>
                    <div>
                      <p className={"date1"}>
                        {filterDate(get(item, "DocDueDate", ""))}
                      </p>
                    </div>
                  </td>

                  <td>
                    <button
                      className='confirm'
                      onClick={() =>
                        navigate("/insideOrder", {
                          state: {
                            data: get(item, "DocumentLines", []),
                            CardCode: get(item, "CardCode", ""),
                            DocCurrency: get(item, "DocCurrency"),
                          },
                        })
                      }
                    >
                      Информация
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {!(nextLink == 0 && data.length > 0) > 1 ? (
          <div className='addCard'>
            <button className='btnTop' onClick={addNewProducts}>
              <AiOutlinePlusCircle />
            </button>
          </div>
        ) : null}
      </>
    );
  }, [data]);

  return (
    <>
      <SalesOrderStyle>
        <div className='header'>
          <button
            className='listTitleBtn'
            onClick={() => navigate("/redirect")}
          >
            {"Меню"}
          </button>
          <img src={Logo} alt='logo' className='img' />
          <button onClick={() => navigate("/profil")} className='btnTop'>
            {"Профиль"}
          </button>
        </div>
        <div className='card'>
          {data.length <= 0 ? (
            <div className='noData'>
              <p className='noDataTitle'>Информация недоступна</p>
            </div>
          ) : (
            salesProducts
          )}
        </div>
      </SalesOrderStyle>
    </>
  );
};

export default SalesOrder;
