import React from "react";
import YesNoModalStyle from "./YesNoModalStyle";

const YesNoModal = ({ isOpen, onConfirm, onClose }) => {
  return (
    <YesNoModalStyle>
      <div className={!isOpen ? "none" : "modal"}>
        <div className='card'>
          <h2>Xom-ashyoni qabul qilasizmi ?</h2>
          <div className='centerCard'>
            <button className='btnN' onClick={() => onClose()}>
              Yo'q
            </button>
            <button className='btnY' onClick={() => onConfirm()}>
              Ha
            </button>
          </div>
        </div>
      </div>
    </YesNoModalStyle>
  );
};

export default YesNoModal;
