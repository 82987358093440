import React, { memo, useEffect } from "react";
import ErrorModalStyle from "./ErrorModalStyle";
import ErrorImage from "../../../assets/images/error.png";

const ErrorModal = ({
  isOpen,
  onConfirm,
  title = "Убедитесь, что Интернет подключен",
}) => {
  useEffect(() => {
    setTimeout(() => {
      onConfirm();
    }, 4000);
  }, []);

  return (
    <ErrorModalStyle>
      <div className={!isOpen ? "none" : "modal"}>
        <div className='card'>
          <img src={ErrorImage} alt='error image' className='img' />
          <h2 className='title'>{title}</h2>
          <div className='centerCard'>
            <button className='btnN' onClick={() => onConfirm()}>
              {"Понятно"}
            </button>
          </div>
        </div>
      </div>
    </ErrorModalStyle>
  );
};

export default memo(ErrorModal);
