import styled from "styled-components";

const RedirectStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100vh;
  flex-wrap: wrap;
  .container {
    flex: 1;
    text-align: center;
    margin: auto;
    .btn {
      width: 300px;
      min-height: 50px;
      margin-bottom: 20px;
    }
  }
`;
export default RedirectStyle;
