import React, { memo, useState } from "react";
import EditModalStyle from "./EditModalStyle";

const EditModal = ({
  isOpen,
  onConfirm,
  onClose,
  productNum,
  skladNum,
  index,
}) => {
  const [productNumE, setProductNumE] = useState(productNum);

  return (
    <EditModalStyle>
      <div className={!isOpen ? "none" : "modal"}>
        <div className='card'>
          <h2>{"Изменить количество товаров!"}</h2>
          <p>Количество товаров</p>
          <input
            type='number'
            placeholder={productNum}
            className='input'
            onChange={(e) => setProductNumE(e.target.value)}
          />
          <p>{"Номер склада"}</p>
          <input
            type='text'
            disabled={true}
            placeholder={skladNum}
            className='input'
          />
          <div className='centerCard'>
            <button className='btnN' onClick={() => onClose()}>
              {"Нет"}
            </button>
            <button
              className='btnY'
              onClick={() => onConfirm(index, productNumE, skladNum)}
            >
              {"Да"}
            </button>
          </div>
        </div>
      </div>
    </EditModalStyle>
  );
};

export default memo(EditModal);
