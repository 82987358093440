import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import RedirectStyle from "./RedirectStyle";
import { useNavigate } from "react-router-dom";

const Redirect = () => {
  const navigate = useNavigate();

  const { userName } = useSelector((state) => state.main);
  const JobTitle = get(userName[0], "JobTitle", "");

  return (
    <RedirectStyle>
      <div className='container'>
        <Button className={"btn"} onClick={() => navigate("/main")}>
          Tayyor bo'lgan mahsulotlar
        </Button>

        {JobTitle === "Омборчи" ? (
          <Button onClick={() => navigate("/salesOrder")} className='btn'>
            Sotuv jarayoni
          </Button>
        ) : null}
      </div>
    </RedirectStyle>
  );
};

export default Redirect;
