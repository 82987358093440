import React, {useState, useCallback, useEffect} from 'react';
import LoginStyle from './LoginStyle';
import {useDispatch, useSelector} from 'react-redux';
import {main} from '../../store/slices';
import api from '../../api';
import {get} from 'lodash';
import {useNavigate} from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import Button from '../../components/Button';
import {ErrorModal} from '../../components/Modal';

const Login = () => {
  const {setMe, setInfo, setLogins, setToken, setU_prefix, setU_prefixO} =
    main.actions;
  const {info} = useSelector(state => state.main);
  const loginValue = get(info, 'login', '');
  const passwordValue = get(info, 'password', '');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, setLogin] = useState(loginValue);
  const [password, setPassword] = useState(passwordValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const loginProfile = useCallback(() => {
    setIsLoading(true);
    api
      .post('/Login', {
        CompanyDB: 'AE04_PROD1',
        UserName: `sb1\\aez_user002a`,
        Password: `huM@9-x9`,
      })
      .then(res => {
        securePassword(get(JSON.parse(res.data), 'SessionId', ''));
        dispatch(setToken(get(JSON.parse(res.data), 'SessionId', '')));
      })
      .catch(err => {
        console.log('err Login', err);
        setIsLoading(false);
        setIsError(true);
      });
  }, [login, password]);

  const securePassword = () => {
    const config = {
      withCredentials: true,
    };
    api
      .get(
        `/EmployeesInfo?$select=EmployeeID,LastName,FirstName,JobTitle,U_Warehouses&$filter=EmployeeCode eq '${login}' and ExternalEmployeeNumber eq '${password}'`,
        config,
      )
      .then(res => {
        const isOmborchi =
          get(JSON.parse(res.data), 'value[0].JobTitle', '') === 'Омборчи';
        console.log(JSON.parse(res.data));

        const array_prefix = JSON.parse(res.data).value[0].U_Warehouses
          ? JSON.parse(res.data).value[0].U_Warehouses.split(' ')
          : [' '];

        const uniqueChars = array_prefix.filter(v => v !== '');

        const U_prefix = uniqueChars.map((v, index) => {
          if (uniqueChars.length <= 1) {
            return `(Warehouse eq '${v}') and `;
          } else if (index === uniqueChars.length - 1) {
            return `or Warehouse eq '${v}') and `;
          } else if (index === 0) {
            return `(Warehouse eq '${v}'`;
          } else return `or Warehouse eq '${v}'`;
        });

        const prx = `ProductionOrders?$expand=BusinessPartner($select=CardName)&$filter=${
          isOmborchi ? '' : U_prefix.toString().replace(/ ,/g, ' ')
        } ProductionOrderStatus ne 'boposClosed' and ProductionOrderStatus ne 'boposCancelled'  &$orderby=AbsoluteEntry`;

        const prx2 = isOmborchi
          ? `/SQLQueries('getServiceOrdersForOmborchi')/List`
          : `/SQLQueries('getServiceOrdersForBrigadir')/List?WhsCode1=${get(
              uniqueChars,
              '[0]',
              '99',
            )}&WhsCode2=${get(uniqueChars, '[1]', '99')}&WhsCode3=${get(
              uniqueChars,
              '[2]',
              '99',
            )}`;

        if (JSON.parse(res.data).value.length === 1) {
          dispatch(setU_prefix(prx));
          dispatch(setU_prefixO(prx2));
          dispatch(setMe(JSON.parse(res.data).value));
          dispatch(setInfo({login, password}));
          dispatch(setLogins(true));
          navigate('/redirect');
        } else if (JSON.parse(res.data).value.length > 1) {
          alert('Логин-пароль другого пользователя такой же !');
        } else if (JSON.parse(res.data).value.length < 1) {
          alert(
            'Ошибка логина или пароля, пожалуйста, проверьте и введите еще раз !',
          );
        }
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setIsError(true);
        console.log('EmployeesInfo', err);
      });
  };
  return (
    <>
      <LoginStyle>
        <div className="container">
          <img src={Logo} alt="logo" className="img" />

          <div style={{width: '100%'}}>
            <div className="card">
              <div>
                <label htmlFor="login" className="label">
                  Login
                </label>
              </div>
              <input
                type="text"
                id="login"
                placeholder="Login"
                defaultValue={loginValue}
                className="input"
                onChange={e => setLogin(e.target.value)}
              />
            </div>
            <div className="card">
              <div>
                <label htmlFor="password" className="label">
                  Password
                </label>
              </div>
              <input
                type="text"
                id="password"
                placeholder="Password"
                defaultValue={passwordValue}
                className="input"
                onChange={e => setPassword(e.target.value)}
              />
            </div>

            <div style={{textAlign: 'center'}}>
              <Button
                onClick={() => loginProfile()}
                children={'Вход'}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </LoginStyle>
      <ErrorModal
        isOpen={isError}
        onConfirm={() => setIsError(false)}
        title={'Ошибка логина или пароля, проверьте еще раз!'}
      />
    </>
  );
};

export default Login;
