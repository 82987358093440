import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import api from "../../api";
import Logo from "../../assets/images/logo.png";
import { AiOutlinePlusCircle } from "react-icons/ai";
import InsideOrderStyle from "./styles";
import moment from "moment";
import Button from "../../components/Button";
import { SuccessModal } from "../../components/Modal";
import EditModal from "./EditModal";

const InsideOrder = () => {
  const { state } = useLocation();
  const data = get(state, "data", []);

  const navigate = useNavigate();

  const [productData, setProductData] = useState(data);
  const [isLoading, setLoading] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const [quantityEdit, setQuantityEdit] = useState("s");
  const [cardCodeEdit, setCardCodeEdit] = useState("s");
  const [indexEdit, setIndexEdit] = useState(0);

  const copyForSale = () => {
    const DocumentLines = data.map((item) => {
      setLoading(true);
      return {
        ItemCode: get(item, "ItemCode", 0),
        Quantity: get(item, "Quantity", ""),
        WarehouseCode: get(item, "WarehouseCode", ""),
        BaseEntry: get(item, "DocEntry", ""),
        BaseLine: get(item, "LineNum", ""),
        Currency: get(item, "Currency", ""),
        Price: get(item, "Price", 0),
        BaseType: 17, //static val
      };
    });
    const filterData = {
      CardCode: `${state.CardCode}`,
      DocCurrency: state.DocCurrency,
      DocumentLines: DocumentLines,
    };

    api
      .post("/Invoices", JSON.stringify(filterData))
      .then(() => {
        setLoading(false);
        setIsOpenSuccessModal(true);
      })
      .catch((error) => {
        setLoading(false);
        alert("Что-то пошло не так");
        console.log(JSON.parse(error.response.data).error.message.value);
      });
  };

  useEffect(() => {
    setProductData(data);
  }, []);

  const closeSuccessModal = () => {
    setIsOpenSuccessModal(false);
  };

  const closeEditModal = () => {
    setIsOpenEditModal(false);
  };

  const openEditModal = (q, c, i) => {
    setQuantityEdit(q);
    setCardCodeEdit(c);
    setIndexEdit(i);
    setIsOpenEditModal(true);
  };

  const salesProducts = useMemo(() => {
    return (
      <>
        <table className='table'>
          <thead>
            <tr>
              <th>N</th>
              <th>Название продукта</th>
              <th>Tovar soni</th>
              <th>Sklad raqami</th>
              <th>Принять</th>
            </tr>
          </thead>
          <tbody>
            {productData.map((item, index) => {
              return (
                <tr key={index} className='mainTr'>
                  <td className='ID'>{index + 1}</td>
                  <td className='title'>{get(item, "ItemDescription", "s")}</td>
                  <td>{Math.floor(get(item, "Quantity", 0))}</td>
                  <td> {get(item, "WarehouseCode", "")}</td>

                  <td>
                    <button
                      className='confirm'
                      onClick={() =>
                        openEditModal(
                          Math.floor(Number(get(item, "Quantity", 0))),
                          get(item, "WarehouseCode", ""),
                          index
                        )
                      }
                    >
                      Изменить
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }, [productData, data]);

  return (
    <>
      <InsideOrderStyle>
        <div className='header'>
          <button
            className='listTitleBtn'
            onClick={() => navigate("/salesOrder")}
          >
            {"Назад"}
          </button>
          <img src={Logo} alt='logo' className='img' />
          <button onClick={() => navigate("/profil")} className='btnTop'>
            {"Профиль"}
          </button>
        </div>
        <div className='card'>
          {data.length <= 0 ? (
            <div className='noData'>
              <p className='noDataTitle'>Информация недоступна</p>
            </div>
          ) : (
            salesProducts
          )}
          <div className='rightCard'>
            <Button
              style={"btn"}
              disableStyle={{ width: 350, height: 50 }}
              onClick={copyForSale}
              isLoading={isLoading}
            >
              Sotish uchun nusxalash
            </Button>
          </div>
        </div>
        <SuccessModal
          isOpen={isOpenSuccessModal}
          onConfirm={closeSuccessModal}
        />
        <EditModal
          isOpen={isOpenEditModal}
          productNum={quantityEdit}
          skladNum={cardCodeEdit}
          index={indexEdit}
          onClose={closeEditModal}
          onConfirm={(idx, productNum, stockNum) => {
            setIsOpenEditModal(false);
            const updateProductData = productData.map((item, index) => {
              if (idx === index) {
                return {
                  ...item,
                  Quantity: productNum,
                  WarehouseCode: stockNum,
                };
              }
              return item;
            });
            setProductData(updateProductData);
          }}
        />
      </InsideOrderStyle>
    </>
  );
};

export default InsideOrder;
