import React from "react";
import LogoutModalStyle from "./LogoutModalStyle";

const LogoutModal = ({ isOpen, onConfirm, onClose }) => {
  return (
    <LogoutModalStyle>
      <div className={!isOpen ? "none" : "modal"}>
        <div className='card'>
          <h2>Вы действительно хотите выйти ?</h2>
          <div className='centerCard'>
            <button className='btnN' onClick={() => onClose()}>
              Нет
            </button>
            <button className='btnY' onClick={() => onConfirm()}>
              Да
            </button>
          </div>
        </div>
      </div>
    </LogoutModalStyle>
  );
};

export default LogoutModal;
